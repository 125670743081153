label + .MuiInput-formControl{
    margin-top: 0;
}

.MuiFormControl-marginNormal {
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.ver_regras{
    color:rgb(98, 110, 212) !important;
    cursor:pointer !important;
    font-weight: bold;
}

.ver_regras:hover{
    color:rgb(98, 110, 150) !important;

}


.text_overflow{
    display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
text-align: justify;
}

.click_input{
    border-radius: 100px;
    padding:3px
}


.click_input:hover{
    background-color: #f2f2f2;
}

.click_input:after{
    background-color: lightgray;
}

.click_input:active:after{
    background-color: lightgray;
}