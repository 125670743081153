.input-image-box {
    width: 7em;
    height: 7em;
    background-color: whitesmoke;
    transition: .5s ease-in-out;
    background-repeat: no-repeat;
    background-size: cover;
}

.input-image-box:hover {
    filter: brightness(0.5);
}

/* .input-image-box:hover::after {
    content: "+";
    position: absolute;
    font-size: 5em;
    font-weight: 0;
    color: whitesmoke;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */

.cards-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.course-card {
    width: 35em;
    min-width: 35em;
    /* min-width: fit-content;
    max-width: 100%; */
    /* height: 12em; */
    height: fit-content;
    margin: 1em;
    border-radius: 0.5em;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.35);
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.35);
    transition: .2s;
}

.course-card:hover {
    -webkit-box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.35);
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.35);
}

/* .course-card-bar {
    height: 10px;
    width: 100%;
    background-color: green;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
} */

.course-card-img {
    width: 10em;
    /* height: 12em; */
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
}

.course-card-text {
    /* background-color: white; */
    overflow: hidden;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    padding: 10px;
}

.course-card-title {
    color: black;
    font-weight: 500;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.course-card-actions {
    padding: 10px;
}

@media only screen and (max-width: 576px) {

    .cards-group {
        justify-content: space-around;
    }

    .course-card {
        flex: none;
        /* min-width: fit-content;
        max-width: none; */
        min-width: 60vw;
        width: 60vw;
        height: auto;
    }

    .course-card-body {
        flex-direction: column;
    }

    .course-card-text {
        width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 7px;
    }

    .course-card-img {
        width: 100%;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}