label + .MuiInput-formControl{
    margin-top: 0;
}

.MuiFormControl-marginNormal {
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.ver_regras{
    color:rgb(98, 110, 212) !important;
    cursor:pointer !important;
    font-weight: bold;
}

.ver_regras:hover{
    color:rgb(98, 110, 150) !important;

}