/* .card-tr:hover {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
} */

.td-img {
    width: 0;
    border-right: none;
}

.td-icons {
    vertical-align: middle;
    white-space: nowrap;
}

.td-description {
    overflow: hidden;
}

.div-description {
    height: 50px;
    word-break: break-all;
    overflow: hidden;
    text-align: left;
    flex-basis: 30%;
}

.card-tr:hover .thumbnail-box {
    height: 5em;
    opacity: 1;
}

.thumbnail-box {
    height: 0;
    overflow: hidden;
    transition: 0.2s;
    transition-delay: .3s;
    opacity: .2;
    margin-right: 15px;
}

.card-tr:hover .course-thumbnail {
    width: 6em;
    height: 6em;
}

.course-thumbnail {
    height: 0;
    width: 0;
    object-fit: cover;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
    /* transition: width .2s linear .3s, height .2s linear 0.2s; */
    transition: .2s ease-in-out .3s;
    margin-right: 15px;
}