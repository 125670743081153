:root {
  --primary-color: #4a90e2;
  --secondary-color: #3a7bc8;
  --background-color: #f9fafc;
  --text-color: #2c3e50;
  --border-color: #e0e6ed;
  --shadow-color: rgba(0, 0, 0, 0.08);
  --transition-duration: 0.3s;
}

body,
html,
.chat-container {
  height: 100%;
  margin: 0;
  padding: 0;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.context-section {
  padding: 1rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
}

.context-summary {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.context-summary .card-body {
  padding: 1rem;
}

.context-summary .card-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.context-summary .card-text {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.chat-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
}

.chat-footer {
  padding: 1rem;
  background-color: #f8f9fa;
  border-top: 1px solid #e0e0e0;
}

.chat-header {
  background-color: var(--primary-color);
  padding: 1.2rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.3rem;
  transition: background-color var(--transition-duration) ease;
  display: flex;
  align-items: center;
}

.chat-header:hover {
  background-color: var(--secondary-color);
}

.chat-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1.8rem;
  background-color: var(--background-color);
  height: calc(100% - 150px);
}

.message {
  display: flex;
  margin-bottom: 15px;
  animation: slideIn 0.5s ease-out;
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.message-bubble {
  background-color: #fff;
  border-radius: 18px;
  padding: 1.2rem;
  box-shadow: 0 2px 10px var(--shadow-color);
  font-size: 16px;
  line-height: 1.6;
  transition: all var(--transition-duration) ease;
  max-width: 75%;
}

.message-bubble:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px var(--shadow-color);
}

.message-bubble.bot {
  background-color: var(--background-color);
  color: var(--text-color);
}

.message-bubble.user {
  background-color: var(--primary-color);
  color: #fff;
}

.message-intro {
  background-color: #fff;
  color: #4a4a4a;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 18px 24px;
  max-width: 80%;
  margin: 25px auto;
  text-align: center;
  font-style: italic;
  font-size: 1rem;
  box-shadow: 0 4px 15px var(--shadow-color);
  transition: all var(--transition-duration) ease;
}

.message-intro:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 20px var(--shadow-color);
}

.chat-footer {
  background-color: #fff;
  border-top: 1px solid var(--border-color);
  padding: 1.2rem;
}

.options-step {
  background-color: #fff;
  border-radius: 12px;
  padding: 1.8rem;
  box-shadow: 0 3px 12px var(--shadow-color);
  margin-bottom: 1.8rem;
  transition: all var(--transition-duration) ease;
}

.options-step:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px var(--shadow-color);
}

.options-step h3 {
  margin-bottom: 1rem;
  color: #333;
  font-weight: 600;
}

.btn {
  border-radius: 25px;
  padding: 12px 24px;
  font-weight: 600;
  transition: all var(--transition-duration) ease;
  border: none;
  box-shadow: 0 3px 8px var(--shadow-color);
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px var(--shadow-color);
}

.form-control {
  border-radius: 25px;
  padding: 14px 22px;
  border: 1px solid var(--border-color);
  transition: all var(--transition-duration) ease;
  font-size: 16px;
}

.form-control:focus {
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.15);
  border-color: var(--primary-color);
}

.btn-option {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 3px 12px var(--shadow-color);
}

.chat-header-icon {
  margin-right: 10px;
  font-size: 1.5rem;
}

.message-time {
  font-size: 0.8rem;
  color: #a0a0a0;
  margin-top: 5px;
}

.typing-indicator {
  display: flex;
  padding: 10px;
  background-color: var(--background-color);
  border-radius: 18px;
  margin-bottom: 15px;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px;
  animation: typing 1s infinite;
}

@keyframes typing {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.habilidades-checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.habilidades-checkboxes .form-check {
  flex-basis: calc(50% - 10px);
}

@media (max-width: 768px) {
  .habilidades-checkboxes .form-check {
    flex-basis: 100%;
  }
}

.outra-materia-input {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.outra-materia-input.show {
  max-height: 50px; /* Ajuste conforme necessário */
}

.habilidades-selecionadas {
  margin-top: 15px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  border: 1px solid #e0e6ed;
}

.habilidades-selecionadas .badge {
  font-size: 0.9rem;
  font-weight: normal;
}

.habilidades-selecionadas .badge svg {
  font-size: 0.8rem;
}

.message .card-footer {
  background-color: transparent;
  border-top: none;
  padding-top: 0;
}

.message .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.message textarea {
  margin-bottom: 10px;
}