.btn-back {
    background-color: var(--gray-blue);
    border: var(--gray-blue);
}

.btn-back:hover {
    background-color: var(--dark-gray-blue);
}

.btn-back:active, .btn-back:focus {
    background-color: var(--dark-gray-blue);
    filter: brightness(1.1);
}