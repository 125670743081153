.ver_regras{
    color:rgb(98, 110, 212) !important;
    cursor:pointer !important;
    font-weight: bold;
}

.ver_regras:hover{
    color:rgb(98, 110, 150) !important;

}

.copy{
    margin-left: 10px;
    font-size: 20px;
    color: grey;
    cursor: pointer;
}
.copy:hover{
    color: darkgrey;
}