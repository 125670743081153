.lesson-card {
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}

.card-body {
    overflow: hidden;
}

.card-body .description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.description-expand {
    overflow: visible !important;
    white-space: normal !important;
    text-overflow: unset !important;
    overflow-wrap: break-word;
}